var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col gap-3"},[_c('div',{staticClass:"flex flex-col gap-4 w-full"},[_c('div',{staticClass:"flex flex-col gap-2 w-full sm:w-2/3"},[_c('span',[_vm._v("Supplier Credit Note Number")]),_c('vs-input',{staticClass:"w-full",model:{value:(_vm.formHeaderModels.cnNumber),callback:function ($$v) {_vm.$set(_vm.formHeaderModels, "cnNumber", $$v)},expression:"formHeaderModels.cnNumber"}})],1),_c('div',{staticClass:"flex flex-col gap-3 w-full sm:w-2/3"},[_c('span',[_vm._v("Supplier Credit Note Date")]),_c('datepicker',{staticClass:"w-full",attrs:{"inline":false,"name":"Debit Note Date","placeholder":"Select Debit Note Date","disabled-dates":{
          from: new Date(),
        }},model:{value:(_vm.formHeaderModels.cnDate),callback:function ($$v) {_vm.$set(_vm.formHeaderModels, "cnDate", $$v)},expression:"formHeaderModels.cnDate"}})],1),_c('div',{staticClass:"flex flex-col gap-3 w-full sm:w-2/3"},[_c('span',[_vm._v("Tax Return Number")]),_c('vs-input',{staticClass:"w-full",attrs:{"disabled":""},model:{value:(_vm.formHeaderModels.trNumber),callback:function ($$v) {_vm.$set(_vm.formHeaderModels, "trNumber", $$v)},expression:"formHeaderModels.trNumber"}})],1),_c('div',{staticClass:"flex flex-col gap-3 w-full sm:w-2/3"},[_c('span',[_vm._v("Tax Return Date")]),_c('datepicker',{staticClass:"w-full",attrs:{"inline":false,"name":"Tax Return Date","placeholder":"Select Tax Return Date","disabled-dates":{
          from: new Date(),
        }},model:{value:(_vm.formHeaderModels.trDate),callback:function ($$v) {_vm.$set(_vm.formHeaderModels, "trDate", $$v)},expression:"formHeaderModels.trDate"}})],1),_c('div',{staticClass:"flex flex-col gap-3 w-full sm:w-2/3"},[_c('span',[_vm._v("Settlement Method")]),_c('multiselect',{staticClass:"selectExample",attrs:{"options":!_vm.settlementMethodOptions || _vm.settlementMethodOptions.length == 0
            ? []
            : _vm.settlementMethodOptions,"multiple":false,"allow-empty":true,"group-select":false,"max-height":160,"limit":4,"placeholder":"Type to search","track-by":"SettlementMethod","label":"SettlementMethod","disabled":false},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('div',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title"},[_vm._v(" "+_vm._s(props.option.SettlementMethod)+" ")])])]}}]),model:{value:(_vm.formHeaderModels.settlementMethod),callback:function ($$v) {_vm.$set(_vm.formHeaderModels, "settlementMethod", $$v)},expression:"formHeaderModels.settlementMethod"}})],1),_c('div',{staticClass:"flex flex-col gap-3 w-full sm:w-2/3"},[_c('span',[_vm._v("Tax Invoice Reference")]),_c('div',{staticClass:"flex gap-3 items-center"},[_c('multiselect',{staticClass:"w-1/2",attrs:{"options":!_vm.taxInvoiceReferenceOptions ||
            _vm.taxInvoiceReferenceOptions.length == 0
              ? []
              : _vm.taxInvoiceReferenceOptions,"multiple":false,"allow-empty":true,"group-select":false,"max-height":160,"limit":4,"placeholder":"Type to search","track-by":"id","custom-label":_vm.queryTaxInvoiceReference,"disabled":false},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('div',{staticClass:"option__desc"},[(props.option.tax_return_number && props.option.value)?_c('span',{staticClass:"option__title"},[_vm._v(" "+_vm._s(props.option.tax_return_number)+" - "+_vm._s(_vm.priceFormat(props.option.value))+" ")]):(props.option.tax_return_number)?_c('span',{staticClass:"option__desc"},[_vm._v(" "+_vm._s(props.option.tax_return_number)+" ")]):_c('span',{staticClass:"option__desc"},[_vm._v(" "+_vm._s(props.option.label)+" ")])])]}}]),model:{value:(_vm.formHeaderModels.taxInvoiceReference),callback:function ($$v) {_vm.$set(_vm.formHeaderModels, "taxInvoiceReference", $$v)},expression:"formHeaderModels.taxInvoiceReference"}}),_c('vs-input',{staticClass:"w-1/2",attrs:{"disabled":this.formHeaderModels.taxInvoiceReference &&
            Object.keys(this.formHeaderModels.taxInvoiceReference).length > 0},model:{value:(_vm.formHeaderModels.taxInvoiceReferenceCode),callback:function ($$v) {_vm.$set(_vm.formHeaderModels, "taxInvoiceReferenceCode", $$v)},expression:"formHeaderModels.taxInvoiceReferenceCode"}})],1)]),_c('div',{staticClass:"flex flex-col gap-3 w-full sm:w-2/3"},[_c('span',[_vm._v("Tax Invoice Reference Date")]),_c('datepicker',{staticClass:"w-full",attrs:{"inline":false,"name":"Tax Invoice Reference Date","placeholder":"Select Tax Invoice Reference Date","disabled-dates":{
          from: new Date(),
        }},model:{value:(_vm.formHeaderModels.taxInvoiceReferenceRefDate),callback:function ($$v) {_vm.$set(_vm.formHeaderModels, "taxInvoiceReferenceRefDate", $$v)},expression:"formHeaderModels.taxInvoiceReferenceRefDate"}})],1),_c('div',{staticClass:"flex flex-col gap-3 w-full sm:w-2/3"},[_c('span',[_vm._v("Posting Date")]),_c('datepicker',{staticClass:"w-full",attrs:{"inline":false,"name":"Posting Date","placeholder":"Select Posting Date","disabled-dates":{
          from: new Date(),
        }},model:{value:(_vm.formHeaderModels.postingDate),callback:function ($$v) {_vm.$set(_vm.formHeaderModels, "postingDate", $$v)},expression:"formHeaderModels.postingDate"}})],1),_c('div',{staticClass:"flex flex-col gap-3 w-full sm:w-2/3"},[_c('span',[_vm._v("Note")]),_c('vs-input',{staticClass:"w-full",model:{value:(_vm.formHeaderModels.note),callback:function ($$v) {_vm.$set(_vm.formHeaderModels, "note", $$v)},expression:"formHeaderModels.note"}})],1),_c('div',{staticClass:"flex flex-col gap-3 w-full sm:w-2/3"},[_c('span',[_vm._v("Attachment")]),(_vm.attachment.length > 0)?_c('div',_vm._l((_vm.attachment),function(tr,i){return _c('div',{key:i,staticClass:"flex justify-start items-center gap-6"},[_c('span',[_vm._v(_vm._s(tr.NameFile))]),_c('vx-tooltip',{staticStyle:{"width":"fit-content"},attrs:{"text":"Delete"}},[_c('vs-button',{attrs:{"color":"danger","icon-pack":"feather","icon":"icon-trash"},on:{"click":function($event){return _vm.handleShowPrompt(tr)}}})],1)],1)}),0):_c('vs-input',{staticClass:"w-full",attrs:{"type":"file"},on:{"change":_vm.onFileChange},model:{value:(_vm.fileName),callback:function ($$v) {_vm.fileName=$$v},expression:"fileName"}})],1)]),_c('vs-prompt',{attrs:{"title":"Confirmation","color":"danger","buttons-hidden":false,"active":_vm.isPromptShow},on:{"cancel":_vm.handleClose,"close":_vm.handleClose,"accept":function($event){_vm.deleteFile(_vm.selectedData.ID, _vm.getAttachmentIndex(_vm.selectedData.ID))},"update:active":function($event){_vm.isPromptShow=$event}}},[_c('div',{staticClass:"con-exemple-prompt"},[_vm._v(" Debit Note "),_c('br'),_vm._v(" Are you sure to delete attachment file "),_c('b',[_vm._v(_vm._s(this.selectedData.NameFile ? this.selectedData.NameFile : ""))]),_vm._v(" ? ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }